<template>
    <v-list-item>
        <v-list-item-content class="text-start">
        <template v-if="link">
            <span><router-link :to="{ name: 'account-edit-volume', params: { accountId: this.$route.params.accountId, volumeId: id }, query: { alias: this.$route.params.alias }}">{{ label }}</router-link></span>
        </template>
        <template v-if="!link">
            <span>{{ label }}</span>
        </template>
        <p class="text-caption mb-0">{{ alias }}</p>
        <!-- TODO: a property for selecting whether to show the id on a 3rd line or not -->
        <!-- <p class="text-caption">{{ id }}</p> -->
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {

    props: {
        // whether the item should link to the account record; a parent component may set this to false if it just wants the user to select an account (and not navigate to it)
        link: {
            type: Boolean,
            default: false,
        },
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        alias() {
            return this.attr.alias;
        },
        label() {
            return this.attr.label;
        },
    },

};
</script>
