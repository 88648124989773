<template>
    <v-card tile elevation="4" class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="purple--text">{{label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="purple" @click="openCreateVolumeDialog">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn>
            <!-- <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn icon color="purple" v-on="on">
                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                    </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item-group>
                    <v-list-item :to="{ name: 'service-admin-site-settings' }">
                        <v-list-item-content>
                            <v-list-item-title>Settings</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu> -->
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No volumes yet.</v-list-item>
            <!-- <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <v-list-item-content class="text-start"> -->
                    <!-- TODO: a property for selecting whether to show the id on a 3rd line or not, selected by an option in the "settings" menu on the card -->
                    <VolumeListItem v-for="item in list" v-bind:key="item.id" @click="onClickItem(item.id)" :attr="item" :link="!selectOne"></VolumeListItem>
                <!-- </v-list-item-content>
            </v-list-item> -->
        </v-list>
        <!-- <v-row justify="center" class="py-5" v-show="displayCreateVolume">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <v-card elevation="4" class="px-10 pb-5 pt-8">
                <v-form v-model="createVolumeForm" ref="createVolumeFormRef" @submit="validateCreateVolume" onSubmit="return false;" @keyup.enter.native="validateCreateVolume">
                    <div v-if="add">
                        <v-select :items="addVolumeChoices" v-model="addVolumeId" label="Select an volume"></v-select>
                    </div>
                    <div v-if="create">
                        <v-text-field
                            v-model=name
                            label="Name"
                            :rules="nameRules"
                            validate-on-blur
                            color="purple"
                            required
                            hint="What should we call you?"
                            autofocus
                            outlined
                        >
                                            <template v-slot:prepend>
                        <font-awesome-icon icon="site" fixed-width class="mt-1"/>
                    </template>
                        </v-text-field>
                        <v-text-field
                            ref="nameInputRef"
                            v-model=newVolumeName
                            label="Display Name"
                            :rules="newVolumeNameRules"
                            validate-on-blur
                            color="purple"
                            required
                            hint="The name for the volume"
                            type="text"
                            outlined
                        >
                        </v-text-field>

                    </div>
                    <v-row justify="center">
                        <v-card-actions>
                            <v-btn elevation="4" class="purple white--text" @click="addVolume" :disabled="!createVolumeForm" v-if="add">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Add</span>
                            </v-btn>
                            <v-btn elevation="4" class="purple white--text" @click="createVolume" :disabled="!createVolumeForm" v-if="create">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Create</span>
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                </v-form>
            </v-card>
            </v-col>
        </v-row> -->
        <!-- <CreateVolumeDialog v-model="createVolumeDialogVisible" @cancel="createVolumeDialogVisible = false" @created="createVolumeDialogVisible = false"/> -->
    </v-card>
</template>

<script>
import VolumeListItem from '@/components/list-item/VolumeListItem.vue';
// import CreateVolumeDialog from '@/components/account-dashboard/CreateVolumeDialog.vue';
import { isValidName, compact } from '@/sdk/input';

export default {
    components: {
        VolumeListItem,
        // CreateVolumeDialog,
    },

    props: {
        domain: {
            type: String,
            default: null,
            required: false,
        },
    },

    data: () => ({
        // TODO: eliminate these temporary flags and usage in the template; maybe create a different component for "select a volume"
        selectOne: false,
        add: false,
        create: true,

        list: [],
        // createVolumeDialogVisible: false,
        // create volume
        displayCreateVolume: false,
        createVolumeForm: null,
        newVolumeName: null,
        newVolumeNameRules: [
            (v) => !!v || 'Display name is required',
            (v) => !v || isValidName(compact(v)) || 'Volume name is required',
        ],
        // add volume
        addVolumeChoices: [],
        addVolumeId: null,
    }),

    computed: {
        id() {
            return this.policyId;
        },
        label() {
            if (this.selectOne) {
                return 'Select an volume';
            }
            switch (this.list.length) {
            case 0:
                return 'No volumes';
            case 1:
                return '1 volume';
            default:
                return `${this.list.length} volumes`;
            }
        },
    },

    watch: {
        displayCreateVolume(value) {
            if (value && (this.add || this.create)) {
                this.initAddVolumeChoices();
            }
        },
    },

    methods: {
        async loadVolumeList() {
            try {
                this.$store.commit('loading', { loadVolumeList: true });
                const match = {};
                // if (typeof this.domain === 'string') {
                //     match.domain = this.domain;
                // }
                const response = await this.$client.account(this.$route.params.accountId).volume.list(match);
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadVolumeList failed', err);
            } finally {
                this.$store.commit('loading', { loadVolumeList: false });
            }
        },
        onClickItem(volumeId) {
            if (this.selectOne) {
                this.$emit('selected', { volumeId });
            }
        },
        openCreateVolumeDialog() {
            // this.createVolumeDialogVisible = true;
            this.$router.push({ name: 'account-create-volume', params: { accountId: this.$route.params.accountId }, query: { domain: this.$route.params.domain } });
        },
        // async createVolume() {
        //     this.error = false;
        //     console.log('createVolume');
        //     const request = {
        //         // name: this.name,
        //         name: this.newVolumeName,
        //         // agreeToTerms: this.isAgreeToTermsChecked,
        //         // interactionId: this.interactionId, // will be present if volume arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the volume to an appropriate location after volume is created
        //     };
        //     const response = await this.$client.account(this.$route.params.accountId).site.create(request);
        //     console.log('createVolume response: %o', response);
        //     const { isCreated, id, error } = response;
        //     if (isCreated) {
        //         const volume = { id, ...request };
        //         this.list.push(site);
        //         this.$emit('created-site', volume);
        //         this.$emit('added-site', volume);
        //         this.displayCreateVolume = false;
        //         this.newVolumeName = null;
        //     } else if (error) {
        //         this.error = error;
        //     } else {
        //         this.error = 'Request failed';
        //     }
        // },
        // validateCreateVolume() {
        //     if (this.$refs.createVolumeFormRef.validate()) {
        //         this.createVolume();
        //     }
        // },

        // async addVolume() {
        //     const volume = { id: this.addVolumeId, label: this.addVolumeChoices.find((item) => item.value === this.addVolumeId).text };
        //     this.list.push(site);
        //     this.$emit('added-site', volume);
        //     this.displayCreateVolume = false;
        //     this.addVolumeId = null;
        // },
        // async initAddVolumeChoices() {
        //     // create a map of which volume ids are already in the list
        //     const volumeMap = {};
        //     this.list.forEach((item) => {
        //         volumeMap[item.id] = true;
        //     });
        //     // when we show the add/create volume dialog, load the list of available volumes to add to the policy (list of all volumes less the ones already in the policy)
        //     const result = await this.$client.account(this.$route.params.accountId).site.list();
        //     console.log(`VolumeViewList.vue fetch volumes result: ${JSON.stringify(result)}`);
        //     if (result && result.list) {
        //         // filter the results to remove volumes already in the policy, then convert the available volumes to a choice list for the v-select [ { text, value }, ... ]
        //         this.addVolumeChoices = result.list.filter((item) => !siteMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
        //     }
        // },
    },

    mounted() {
        this.loadVolumeList();
    },
};
</script>
